import 'keen-slider/keen-slider.min.css'

import * as React from "react"

import Icons from '../Icons';
import ProductCard from "./ProductCard"
import { StoreContext } from "../../context/store-context"
import {sortAvailableProducts} from "../../utils/utils"
import { useKeenSlider } from 'keen-slider/react'

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
const ProductListingCarousel = ({products = [], limit}) => {

  const { client } = React.useContext(StoreContext);

  const [productsLatest, setProductsLatest] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1.1,
    mode: "free-snap",
    spacing: 20,
    autoAdjustSlidesPerView: false,
    breakpoints: {
      '(min-width: 375px)': {
        slidesPerView: 1.3,
      },
      '(min-width: 768px)': {
        slidesPerView: 2.5,
      },
      '(min-width: 1024px)': {
        slidesPerView: 3.5,
      },
      '(min-width: 1280px)': {
        slidesPerView: 4.5,
      },
    },
    slideChanged: (s) => {
      setCurrentSlide(s.details().relativeSlide)
    },
  });

  const checkAvailablity = React.useCallback((prods) => {
    const ids = prods.map(x => x.shopifyId);

    client.product.fetchMultiple(ids).then((fresh_products) => {      
      if (fresh_products && fresh_products.length) {        
        setProductsLatest(fresh_products);
      }
    });
  }, [client.product]);


  React.useEffect(() => {
    if (products && products.length) {
      checkAvailablity(products);
    }
    
  }, [checkAvailablity, products]);
  
  
  if (limit) {
    products = products.slice(0, limit);
  }
  
  // sort so available products at front
  products = sortAvailableProducts(products);
  
  if (!products.length) {
    return <div className="">Nothing currently for sale in this section</div>
  }

 
  return (
    <div className="relative w-full product-carousel">
      {slider && (
      <div className="absolute -top-10 right-0 hidden md:block" aria-hidden="true">
        <button
          aria-label="previous"
          className={`w-6 mr-4 ${currentSlide === 0 ? 'opacity-50 pointer-events-none': ''}`}
          onClick={(e) => {
            e.stopPropagation();
            slider.prev();
          }}
          disabled={currentSlide === 0}
        ><Icons type="arrow_left" /></button>

        <button
          aria-label="next"
          className={`w-6 ${currentSlide === slider.details().size - 1 ? 'opacity-50 pointer-events-none': ''}`}
          onClick={(e) => {
            e.stopPropagation();
            slider.next();
          }}
          disabled={currentSlide === slider.details().size - 1}
          ><Icons type="arrow_right" /></button>
      </div>
      )}
        
      <div className="relative -mx-5 lg:-mx-12">
        <div ref={sliderRef} className="keen-slider px-5 lg:px-12">
            {products.map((p, index) => (
              <div key={index} className="keen-slider__slide">
                <ProductCard productsLatest={productsLatest} product={p} key={p.id} eager={index === 0} className="h-full" />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
};


export default ProductListingCarousel
