import * as React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

import ProductCondition from "./ProductCondition"
import { formatPrice } from "../../utils/format-price"
import { getShopifyImage } from "gatsby-source-shopify"
import { getTagCondition } from "../../utils/utils"

const ProductCard = ({ product, eager, className, productsLatest}) => {
  const {
    shopifyId,
    title,
    priceRangeV2,
    slug,
    images,
    // vendor,
    storefrontImages,
    variants,
    tags
  } = product;

  const sorted_imgs = React.useMemo(
    () => {
      return images.sort((a, b) => {
        let a_name = a?.src?.split('/').pop().split('?').shift();
        let b_name = b?.src?.split('/').pop().split('?').shift();
    
        return a_name - b_name;
      });
    },
    [images]
  );



  const firstImage = sorted_imgs.length ? sorted_imgs[0] : null;

  let price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  );
  let comparePrice;
  let firstVariant;

  const defaultImageHeight = 200
  const defaultImageWidth = 200
  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: "fixed",
        width: defaultImageWidth,
        height: defaultImageHeight,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const hasImage = firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length;
  let isAvailable;
  let data_match;
 
  if (productsLatest && productsLatest.length) {
    data_match = productsLatest.find(x => x?.id === shopifyId);
    
    if (data_match) {
      isAvailable = data_match.availableForSale;

      if (data_match?.variants?.length) {
        firstVariant = data_match.variants[0];
        
        price = formatPrice(
          firstVariant.priceV2.currencyCode,
          firstVariant.priceV2.amount
        );

        if (firstVariant.compareAtPriceV2) {
          comparePrice = formatPrice(
            firstVariant.compareAtPriceV2.currencyCode,
            firstVariant.compareAtPriceV2.amount
          );
        }
      }
    }
  }

  if (typeof isAvailable !== 'boolean') {
    isAvailable =  variants.filter(variant => variant.availableForSale).length > 0;
  }


  let sold_msg;
  if (!isAvailable) {
    if (variants.length > 1) {
      sold_msg = 'Sold out';
    } else {
      sold_msg = 'Sold';
    }
  }

  return (
    <Link
      className={`flex flex-col border-none bg-gray-800 hover:bg-purple-800 rounded-lg ${className ? className : ''}`}
      to={slug.replace(/\/$/, "")}
      aria-label={`View ${title} product page`}
    >
      <div className="relative">
      {hasImage
        ? (
          <div className={`rounded-t-lg overflow-hidden aspect-w-4 aspect-h-5 bg-black duration-200 ease-in-out transform ${isAvailable ? '' : 'opacity-40 hover:opacity-100'}`} data-name="product-image-box">
            <GatsbyImage
              alt={firstImage?.altText ?? title}
              image={getImage(firstImage?.gatsbyImageData) ?? storefrontImageData}
              loading={eager ? "eager" : "lazy"}
              className="block pixelate"
            />
            
          </div>
        ) : (
          <div className="rounded-t-lg aspect-w-4 aspect-h-5 bg-black border border-gray-800">
            <div className="text-gray-450 h-full w-full flex items-center justify-center">No image</div>
          </div>
        )
      }
      {!isAvailable ? <span className="absolute text-8xl text-shadow text-red-500 font-bold text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-12 transform">{sold_msg}</span> : null}
      </div>
      <div className="p-4  h-full flex flex-col">
        
        <h2 as="h2" className="flex-1 font-bold text-2xl font-serif text-white">
          {title}
        </h2>

        <div className="flex justify-between items-end mt-4">
          <div>
            {comparePrice ? <div className="text-sm line-through text-gray-400">{comparePrice}</div> : null}
            <div className="text-xl text-green-400">{variants.length > 1 ? <span className="text-sm text-gray-475 block">starting at</span> : null} {price}</div>
          </div>
          <ProductCondition condition={getTagCondition(tags)} />
        </div>
      </div>
    </Link>
  )
};

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    shopifyId
    title
    slug: gatsbyPath(
      filePath: "/store/products/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      src
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, breakpoints: [400,800], sizes: "(max-width: 500px) 200px, (min-width: 1024px) 50vw, 100vw")
    }
    variants {
      shopifyId
      availableForSale
      inventoryQuantity
      legacyResourceId
      title
      price
      selectedOptions {
        name
        value
      }
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    # vendor
    tags
  }
`
export default ProductCard