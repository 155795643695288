import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Icons from "../../components/Icons"
import Layout from "../../components/Layout"
import {Link} from "gatsby"
import ProductListingCarousel from "../../components/store/ProductListingCarousel"
import React from "react"
import Seo from "../../components/seo"
import StarRating from "../../components/store/StarRating"
import { StaticImage } from "gatsby-plugin-image"
import StoreFooter from "../../components/StoreFooter"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    guitarsLatest: shopifyCollection(handle: { eq: "guitars" }) {
      products {
        ...ProductCard
      }
    }

    bassesLatest: shopifyCollection(handle: { eq: "basses" }) {
      products {
        ...ProductCard
      }
    }

    partsLatest: shopifyCollection(handle: { eq: "parts" }) {
      products {
        ...ProductCard
      }
    }
  }
`;

const StoreIndexPage = ({data, pageContext}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  return (<Layout headerOverlap>
    <Seo title="Store" />

    <div className="max-h-50vh overflow-hidden bg-black relative max-w-1920 mx-auto lg:flex lg:items-center">
        <div className="w-full">
          <StaticImage layout="fullWidth" className="h-full block" src="../../assets/images/store/store-hero.jpg" alt="side view of 3 Talman guitars" quality={75}  />
        </div>

        <h1 className="page-heading pl-2 absolute bottom-0 left-0">Store</h1>
    </div>
    
    <div className="bg-dark-night p-5 lg:p-12 lg:pb-8">
      <div className="max-w-1920 mx-auto">
        <div className="mb-10 lg:mb-20"><Breadcrumb crumbs={crumbs} crumbLabel="Store" crumbSeparator="»" /></div>

        <div className="mb-10 p-1 rounded-lg bg-gradient-to-tr from-blue-600 to-pink-600">
          <Link to="/store/shop-pay-installments">
            <div className="flex flex-col md:flex-row justify-center items-center p-4 rounded-lg  text-white">
            
              <Icons type="shop_pay_white" className="block min-w-166px max-w-3/5 sm:max-w-2/5 md:max-w-60 mt-2 mb-8 md:my-0 md:mr-10" />
              <div>
                <h2 className="text-base sm:text-lg md:text-3xl font-serif font-bold">Buy now, pay later with Shop&nbsp;Pay</h2>
                <h3 className="text-xs sm:text-sm md:text-base"><span className="mr-2">Split your purchase into 4 equal, interest-free payments.*</span>  <span className="underline">Learn more</span></h3>  
              </div>
            
            </div>
          </Link>
        </div>

        <section className="flex flex-col">
          <h2 className="store-cat-heading"><Link to="/store/guitars">Guitars <span className="text-lg ml-4 underline">see all ({data?.guitarsLatest?.products.length}) listings &raquo;</span></Link></h2>
          <ProductListingCarousel products={data?.guitarsLatest?.products} limit={10} />
        </section>

        <section className="mt-20 flex flex-col lg:flex-row flex-wrap">
          <h2 className="store-cat-heading"><Link to="/store/basses">Basses <span className="text-lg ml-4 underline">see all ({data?.bassesLatest?.products.length}) listings &raquo;</span></Link></h2>
          <ProductListingCarousel products={data?.bassesLatest?.products} limit={10} />
        </section>

        <section className="mt-20 flex flex-col lg:flex-row flex-wrap">
          <h2 className="store-cat-heading"><Link to="/store/parts">Parts <span className="text-lg ml-4 underline">see all ({data?.partsLatest?.products.length}) listings &raquo;</span></Link></h2>
          <ProductListingCarousel products={data?.partsLatest?.products} limit={10} />
        </section>

        <section className="mt-20 flex flex-col justify-start lg:flex-row flex-wrap lg:items-center">
          <h2 className="store-cat-heading">Reviews</h2>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl md:text-4xl">Opening the case was like opening a long lost treasure chest! Thanks again for selling me such a gorgeous instrument! I plan on keeping it for a very long time.</blockquote>
              <figcaption className="text-gray-400 text-base md:text-lg"><span className="mr-2">- Christian L.</span> <span className="text-gray-475 text-xs">Oct 28th, 2021</span></figcaption>
          </figure>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl md:text-4xl">This is probably one of the best set-up guitars I've ever played. I wish you could be my go-to person for all of my guitar setups. Best online guitar purchase, hands down. Thanks again!</blockquote>
              <figcaption className="text-gray-400 text-base md:text-lg"><span className="mr-2">- Anastasia</span> <span className="text-gray-475 text-xs">Oct 8th, 2021</span></figcaption>
          </figure>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl">Thanks very much for everything! Love the guitar, it makes a perfect pair with my ABB! <span role="img" aria-label="hands pressed together" className="not-italic">🙏</span> Packing was perfect</blockquote>
              <figcaption className="text-gray-400 text-base"><span className="mr-2">- djlavalamp</span> <span className="text-gray-475 text-xs">Oct 13th, 2021</span></figcaption>
          </figure>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl">The guitar arrived safely, 10/10 on the packing! I wanted to thank you personally. I am beyond excited to have such an amazing guitar. Thank you so much.</blockquote>
              <figcaption className="text-gray-400 text-base"><span className="mr-2">- Davis</span> <span className="text-gray-475 text-xs">Oct 8th, 2021</span></figcaption>
          </figure>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl">Great customer service and communication! Super fast shipping and the guitar is even more beautiful in person. It was a gift and my son is thrilled. Thank you!</blockquote>
              <figcaption className="text-gray-400 text-base md:text-lg"><span className="mr-2">- Jaime M.</span> <span className="text-gray-475 text-xs">Sep 6th, 2021 (via Reverb)</span></figcaption>
          </figure>

          <figure className="max-w-prose lg:mx-auto mb-8">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl">Thank you so much for being so helpful with my purchase. 100%</blockquote>
              <figcaption className="text-gray-400 text-base"><span className="mr-2">- Chris T.</span> <span className="text-gray-475 text-xs">Aug 13th, 2021 (via Reverb)</span></figcaption>
          </figure>

          {/* <figure className="max-w-prose lg:mx-auto">
              <StarRating rating="5" />
              <blockquote className="font-serif italic text-2xl">Great experience and cool gear</blockquote>
              <figcaption className="text-gray-400 text-base"><span className="mr-2">- William J.</span> <span className="text-gray-475 text-xs">Aug 12th, 2021 (via Reverb)</span></figcaption>  
          </figure> */}
        </section>

        

        <StoreFooter />

        <div className="mt-10 pt-20 pb-12 border-t border-gray-700 ">
          <h2 className="text-xl md:text-3xl text-center text-gray-400 font-serif font-bold mb-4">Tal's Guide also occasionally sells stuff on Reverb!</h2>
          <a href="https://reverb.grsm.io/shop-talsguide" target="_blank" rel="noopener noreferrer nofollow">
            <StaticImage className="block md:hidden w-full mx-auto" layout="fullWidth" placeholder="dominantColor" src="../../assets/images/store/talsguide-reverb-mobile-banner.jpg" alt="Visit our store on Reverb" breakpoints={[375, 600, 750, 1200]} quality={75}  />
            <StaticImage className="hidden md:block w-full lg:max-w-screen-lg mx-auto" layout="fullWidth" placeholder="dominantColor" src="../../assets/images/store/talsguide-reverb-banner.jpg" alt="Visit our store on Reverb" breakpoints={[768, 1024, 1536, 2048]} quality={90}  />
          </a>
          <p className="max-w-72 mx-auto md:max-w-none md:text-sm text-center mt-4">If you're new to Reverb, <a href="https://reverb.com/refer-a-friend/tals-3c33f575-1761-4b00-b09f-d366c1b3839c" target="_blank" rel="noopener noreferrer nofollow">use my referral link to get $10</a> !</p>
        </div>
      </div>
    </div>
  </Layout>)
};

export default StoreIndexPage