import React from "react"

const StarRating = ({rating, subject}) => {

    if (!subject) {
        subject = "Tal's Guide";
    }

    return <div className="stars" style={{"--rating": rating}} aria-label={`Rating of ${subject} is ${rating} out of 5`} />
};

export default StarRating